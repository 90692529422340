import React from "react";
import Button from "@material-ui/core/Button";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ItemsSelectWithActionsType {
  setSelectedOptions: any;
  selectedOptions: any[];
  options: any[];
  disableInputs: boolean;
  title: string;
}

export default function ItemsSelectWithActions({
  setSelectedOptions,
  selectedOptions,
  options,
  disableInputs,
  title,
}: ItemsSelectWithActionsType) {
  const toggle = (todoIndex: number) => {
    return setSelectedOptions([
      ...selectedOptions.slice(0, todoIndex),
      {
        ...selectedOptions[todoIndex],
        garantie: !selectedOptions[todoIndex].garantie,
      },
      ...selectedOptions.slice(todoIndex + 1, selectedOptions.length),
    ]);
  };

  const updateCant = (todoIndex: number, changedCant: number) => {
    return setSelectedOptions([
      ...selectedOptions.slice(0, todoIndex),
      { ...selectedOptions[todoIndex], cant: changedCant },
      ...selectedOptions.slice(todoIndex + 1, selectedOptions.length),
    ]);
  };

  return (
    <div>
      <Autocomplete
        style={{ marginBottom: 20 }}
        multiple
        disableClearable
        fullWidth={true}
        disabled={disableInputs}
        getOptionSelected={(option, value) => {
          return option.id === value.id;
        }}
        options={options?.sort(
          (a: any, b: any) => -b.nume_ro.localeCompare(a.nume_ro)
        )}
        disableCloseOnSelect
        getOptionLabel={(option) => option.nume_ro.toString()}
        value={selectedOptions}
        onChange={(_, values: any) => {
          console.log(values);
          setSelectedOptions(values);
        }}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.nume_ro}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={title}
            fullWidth
          />
        )}
      />
      <table className="body-table">
        <thead>
          <tr>
            {selectedOptions[0]?.cod && <th className="table-items">Code</th>}
            <th className="table-items">Name</th>
            <th className="table-items">Actions</th>
          </tr>
        </thead>
        <tbody>
          {selectedOptions.length < 1 ? (
            <tr>
              <td style={{ display: "hidden" }}></td>
              <td>
                <p style={{ textAlign: "center", margin: "30px" }}>Este gol</p>
              </td>
            </tr>
          ) : (
            selectedOptions.map((elem: any, rowIndex: number) => (
              <tr key={rowIndex}>
                {elem?.cod && <th className="table-items">{elem?.cod}</th>}
                <th className="table-items">{elem?.nume_ro}</th>
                <th className="table-items">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {/* view button */}
                    {selectedOptions[rowIndex]?.cant !== undefined && (
                      <TextField
                        label="Amount"
                        variant="outlined"
                        type="number"
                        value={selectedOptions[rowIndex]?.cant}
                        InputProps={{ inputProps: { min: 1, max: 20 } }}
                        onChange={(e: any) => {
                          if (e.target.value)
                            updateCant(rowIndex, e.target.value);
                        }}
                        disabled={disableInputs}
                      />
                    )}

                    <Button
                      style={{ marginLeft: 5, marginRight: 5 }}
                      variant="contained"
                      color="primary"
                      onClick={() => toggle(rowIndex)}
                      disabled={disableInputs}
                      startIcon={
                        !selectedOptions[rowIndex]?.garantie ? (
                          <CheckBoxOutlineBlankIcon />
                        ) : (
                          <CheckBoxIcon />
                        )
                      }
                    >
                      Garantie
                    </Button>
                  </div>
                </th>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
