import React, { useState } from "react";
import clsx from "clsx";
import { Button, TextField, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export default function CreateNewPart() {
  const classes = useStyles();

  const newValue = {
    cod: "",
    nume_en: "",
    nume_ro: "",
    pret: "",
  };

  const [values, setValues] = useState(newValue);
  const [displayError, setDisplayError] = useState(false);

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  async function postDefect() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      cod: values.cod.toUpperCase(),
      nume_en: values.nume_en,
      nume_ro: values.nume_ro,
      pret: values.pret,
    };

    if (
      values.cod !== "" &&
      values.nume_en !== "" &&
      values.nume_ro !== "" &&
      values.pret !== ""
    ) {
      try {
        const res = await axios.post(`/add_piesa.php`, reservationData, config);
        if (res.status === 200) setValues({ ...values, ...newValue });
      } catch (error) {
        console.log(error);
      }
    } else {
      setDisplayError(true);
    }
  }

  const newPartInputs = [
    {
      label: "Cod",
      value: values.cod.toUpperCase(),
      handleChange: handleChange("cod"),
      error: values.cod,
      type: "default",
    },
    {
      label: "Nume engleza",
      value: values.nume_en,
      handleChange: handleChange("nume_en"),
      error: values.nume_en,
      type: "default",
    },
    {
      label: "Nume romana",
      value: values.nume_ro,
      handleChange: handleChange("nume_ro"),
      error: values.nume_ro,
      type: "default",
    },
    {
      label: "Pret",
      value: values.pret,
      handleChange: handleChange("pret"),
      error: values.pret,
      type: "number",
    },
  ];

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        {newPartInputs.map((newPartsInput, idx) => {
          const { label, value, handleChange, error, type } = newPartsInput!;
          return (
            <Grid item md={3} sm={6}>
              <TextField
                key={idx}
                variant="outlined"
                fullWidth
                label={label}
                value={value}
                onChange={handleChange}
                inputProps={type === "number" ? { min: "1", step: "1" } : {}}
                type={type}
                error={displayError && error === ""}
                helperText={
                  displayError && error === "" ? "Campul este gol!" : " "
                }
                required
              />
            </Grid>
          );
        })}
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={postDefect}
        fullWidth
      >
        Salvati
      </Button>
    </Paper>
  );
}
