import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import { Link, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import { useState } from "react";
import DrawerComponent from "./DrawerComponent";
import Logo from "../../assets/logo.png";
import useAuth from "../Context/AuthContext/useAuth";
import Logout from "../../Pages/Auth/Logout";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({

//   })
// );

function Navbar() {
  // const classes = useStyles();

  const location = useLocation();
  const { userRoleCx } = useAuth()!;

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenDrawer = (handle: boolean) => {
    setOpenDrawer(handle);
  };

  const verifySU = userRoleCx?.includes("4");

  const loginPage = location.pathname === "/login";

  return (
    <>
      {!loginPage && (
        <>
          <AppBar position="static" color="inherit">
            <Toolbar>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={"100%"}
              >
                <Box display="flex" alignItems="center">
                  {verifySU && (
                    <DrawerComponent
                      openDrawer={openDrawer}
                      handleOpenDrawer={handleOpenDrawer}
                    />
                  )}

                  <Link to="/">
                    <img
                      src={Logo}
                      alt="e-twow logo"
                      style={{ width: 100, height: "auto" }}
                    />
                  </Link>
                </Box>
                <Logout />
              </Box>
            </Toolbar>
          </AppBar>
          <Box marginBottom={5} />
        </>
      )}
    </>
  );
}

export default Navbar;
