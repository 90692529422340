import React from "react";
import { ButtonGroup, Typography, Box, Button } from "@material-ui/core";

interface SheetsFilterProps {
  filterSheetState: string | null;
  changeFilter: (filter: string) => void;
}

const SheetsFilter: React.FC<SheetsFilterProps> = ({
  filterSheetState,
  changeFilter,
}) => {
  return (
    <Box>
      <Typography variant="body1" component="div" gutterBottom>
        <Box fontWeight="fontWeightBold">Stare fise</Box>
      </Typography>
      <ButtonGroup>
        <Button
          style={{
            backgroundColor:
              filterSheetState === "1" ? "rgba(0, 0, 0, 0.08)" : "transparent",
          }}
          onClick={() => changeFilter("1")}
        >
          Fise in lucru
        </Button>

        <Button
          style={{
            backgroundColor:
              filterSheetState === "4" ? "rgba(0, 0, 0, 0.08)" : "transparent",
          }}
          onClick={() => changeFilter("4")}
        >
          Fise finalizate
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default SheetsFilter;
