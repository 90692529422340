import Routes from "./Components/Navigation/Routes";
import "./Components/styles/App.css";
import "./Components/styles/Navbar.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { faBars } from "@fortawesome/free-solid-svg-icons";

import AuthProvider from "./Components/Context/AuthContext/AuthProvider";
import DashboardProvider from "./Components/Context/DashboardContext/DashboardProvider";
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";

library.add(fab, faBars);

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
    fontSize: 15.5,
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <AuthProvider>
          <DashboardProvider>
            <Routes />
          </DashboardProvider>
        </AuthProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
