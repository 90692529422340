import React from "react";

import ClearIcon from "@material-ui/icons/Clear";
import {
  IconButton,
  Box,
  TextField,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import useDashboard from "../../../Components/Context/DashboardContext/useDashboard";

interface TableHeaderProps {
  loading: boolean;
}

export default function TableHeader({ loading }: TableHeaderProps) {
  const { searchValueCx, filterStateCx, setSearchValueCx } = useDashboard()!;

  function handleSearchValue(value: string) {
    setSearchValueCx(value);
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={2}
    >
      <Box display="flex">
        <Typography variant="h4" component="div" gutterBottom>
          <Box fontWeight="fontWeightBold">
            {filterStateCx === "1" ? "Fise in lucru" : "Fise finalizate"}
          </Box>
        </Typography>
      </Box>

      <TextField
        style={{ width: 500 }}
        variant="outlined"
        label="Cauta fisa"
        value={searchValueCx}
        onChange={(e) => handleSearchValue(e.target.value)}
        InputProps={{
          startAdornment: searchValueCx && (
            <IconButton
              onClick={() => handleSearchValue("")}
              disabled={!searchValueCx}
              style={{ order: 1 }}
            >
              <ClearIcon color="disabled" fontSize="small" />
            </IconButton>
          ),
        }}
      />
      <Box width={200} display="flex" justifyContent="flex-end" marginRight={3}>
        {loading ? <CircularProgress size={40} /> : <Box width={"24px"} />}
      </Box>
    </Box>
  );
}
