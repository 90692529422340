import React from "react";
import Button from "@material-ui/core/Button";
import { ButtonGroup, Box, Typography } from "@material-ui/core";

interface AvaibleLocationsProps {
  locations: Array<{
    id: string;
    name: string;
  }>;
  changeFilter: (id: string) => void;
  filterLocation: string;
}

export default function AvaibleLocations({
  locations,
  changeFilter,
  filterLocation,
}: AvaibleLocationsProps) {
  return (
    <Box paddingTop={2}>
      <Typography variant="body1" component="div" gutterBottom>
        <Box fontWeight="fontWeightBold"> Sedii disponibile</Box>
      </Typography>

      <ButtonGroup>
        {locations?.map(({ id, name }) => {
          return (
            <Button
              style={{
                backgroundColor:
                  id === filterLocation ? "rgba(0, 0, 0, 0.08)" : "transparent",
              }}
              key={id}
              onClick={() => changeFilter(id)}
            >
              {name}
            </Button>
          );
        })}
      </ButtonGroup>
    </Box>
  );
}
