import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Box,
  createStyles,
  IconButton,
  Theme,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import BuildIcon from "@material-ui/icons/Build";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Logo from "../../assets/logo.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  })
);

const navigationLinks = [
  { route: "/", label: "Dashboard", icon: <HomeIcon /> },
  {
    route: "/defects",
    label: "Defecte",
    icon: <ReportProblemIcon />,
  },
  {
    route: "/parts",
    label: "Piese",
    icon: <BuildIcon />,
  },
  {
    route: "/generate-report",
    label: "Raport",
    icon: <ListAltIcon />,
  },
];

export default function DrawerComponent({
  openDrawer,
  handleOpenDrawer,
}: {
  openDrawer: boolean;
  handleOpenDrawer: (open: boolean) => void;
}) {
  const classes = useStyles();

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={() => handleOpenDrawer(false)}
      onKeyDown={() => handleOpenDrawer(false)}
    >
      <Box
        marginTop={2}
        marginBottom={2}
        display="flex"
        alignSelf="center"
        justifyContent="center"
      >
        <img
          src={Logo}
          alt="e-twow logo"
          style={{ width: 100, height: "auto" }}
        />
      </Box>
      <List>
        {navigationLinks.map(({ label, route, icon }, index) => (
          <Link to={route} key={index}>
            <ListItem button>
              <ListItemIcon>{icon}</ListItemIcon>

              <ListItemText primary={label} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <div>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={() => handleOpenDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor={"left"}
        open={openDrawer}
        onClose={() => handleOpenDrawer(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}
