import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import UpdateIcon from "@material-ui/icons/Update";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  TextField,
  Button,
  Container,
  ButtonGroup,
  TableContainer,
  Typography,
} from "@material-ui/core";
import AddParts from "./CreateNewPart";
import EditPartModal from "../../Components/utils/EditPart";
import DeleteParts from "../../Components/utils/DeleteConfirm";
import axios from "axios";
import { Box } from "@material-ui/core";

export default function DefectsPage() {
  const classes = useStyles();

  const columns = [
    {
      name: "sheet_number",
      label: "Cod",
    },
    {
      name: "creation_date",
      label: "Nume_RO",
    },
    {
      name: "completion_date",
      label: "Nume_EN",
    },
    {
      name: "price",
      label: "Pret fara tva",
    },
    {
      name: "series",
      label: "Butoane de configurare",
    },
  ];

  interface PartsInterface {
    id: number;
    cod: string;
    nume_en: string;
    nume_ro: string;
    price: string;
  }

  interface NewPartsInterface {
    id: null | number;
    cod: string;
    nume_en: string;
    nume_ro: string;
    price: string;
  }

  const emptyValue = {
    id: null,
    cod: "",
    nume_en: "",
    nume_ro: "",
    price: "",
  };

  const [parts, setParts] = useState<PartsInterface[]>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [tabelPage, setTabelPage] = useState(0);
  const [tabelTotalItems, setTabelTotalItems] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [modalValues, setModalValues] = useState<NewPartsInterface>(emptyValue);

  function handleClickOpen(
    id_part: number,
    cod: string,
    nume_en: string,
    nume_ro: string,
    price: string
  ) {
    setModalValues({
      id: id_part,
      cod: cod,
      nume_en: nume_en,
      nume_ro: nume_ro,
      price: price,
    });
    setOpenModal(true);
  }

  function handleClose() {
    setOpenModal(false);
  }

  // delete confirm
  const [deleteItemId, setDeleteItemId] = useState<{
    id_part: null | number;
    nume_en: string;
  }>({
    id_part: null,
    nume_en: "",
  });

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const [action, setAction] = useState("");

  function handleClickOpenDeleteAlert(id_part: number, nume_en: string) {
    setDeleteItemId({ id_part, nume_en });
    setOpenDeleteAlert(true);
  }

  function handleClickCloseDeleteAlert() {
    setOpenDeleteAlert(false);
  }

  async function getParts() {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      page: tabelPage + 1,
      items_per_page: itemsPerPage,
      query: search,
    };

    try {
      const { data } = await axios.post(
        `/select_piese.php`,
        reservationData,
        config
      );
      const parts = data.parts;
      const totalItems = data.rowNumber;

      setLoading(false);
      setParts(parts);
      setTabelTotalItems(parseInt(totalItems));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const updateDefect = async () => {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const reservationData = {
        id_part: modalValues.id,
        cod: modalValues.cod,
        nume_en: modalValues.nume_en,
        nume_ro: modalValues.nume_ro,
      };

      try {
        await axios.post(`/update_piese.php`, reservationData, config);
        setAction("");
        setLoading(false);
        setModalValues(emptyValue);
        getParts();
      } catch (error) {
        console.log(error);
      }
    };

    const deletePart = async () => {
      setLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const reservationData = {
        id_part: deleteItemId.id_part,
      };

      try {
        await axios.post(`/delete_piesa.php`, reservationData, config);

        setAction("");
        setLoading(false);
        setParts((prev) =>
          prev.filter((item) => item.id !== deleteItemId.id_part)
        );
        setDeleteItemId({ id_part: null, nume_en: "" });
        // getParts();
      } catch (error) {
        console.log(error);
      }
    };

    if (action === "update") updateDefect();
    else if (action === "delete") deletePart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    if (search) {
      if (search.length === 0) {
        getParts();
      } else if (search.length >= 2) {
        getParts();
      }
    } else {
      getParts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabelPage, itemsPerPage, search]);

  function handleChangePage(_: any, newPage: number) {
    setTabelPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setItemsPerPage(parseInt(event.target.value, 10));
  }

  return (
    <>
      <EditPartModal
        handleClose={handleClose}
        openModal={openModal}
        modalValues={modalValues}
        setModalValues={setModalValues}
        title={modalValues.nume_en}
        setAction={setAction}
      />
      <DeleteParts
        handleClose={handleClickCloseDeleteAlert}
        openModalDelete={openDeleteAlert}
        setDeleteConfirm={setAction}
        title={deleteItemId.nume_en}
      />
      <Box margin={3}>
        <AddParts />

        {/* dsadas */}
        <Paper className={classes.root}>
          <Box display="flex" padding={5} justifyContent="space-between">
            <Box width={50} />
            <TextField
              style={{ width: 500 }}
              variant="outlined"
              label="Cauta Piesa"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: search && (
                  <IconButton onClick={() => setSearch("")} disabled={!search}>
                    <ClearIcon color="disabled" fontSize="small" />
                  </IconButton>
                ),
              }}
            />

            {loading ? <CircularProgress /> : <Box width={50} />}
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    return (
                      <TableCell key={column.name}>
                        <Typography component="div">
                          <Box fontWeight="fontWeightBold">{column.label}</Box>
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {parts.map((row: PartsInterface) => {
                  const { cod, nume_ro, nume_en, id, price } = row;
                  return (
                    <TableRow key={id}>
                      <TableCell component="th" scope="row">
                        {cod}
                      </TableCell>
                      <TableCell align="left">{nume_ro}</TableCell>
                      <TableCell align="left">{nume_en}</TableCell>
                      <TableCell align="left">{price} RON</TableCell>

                      <TableCell align="left">
                        <ButtonGroup>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleClickOpen(id, cod, nume_en, nume_ro, price)
                            }
                          >
                            <UpdateIcon />
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              handleClickOpenDeleteAlert(id, nume_en)
                            }
                          >
                            <DeleteIcon />
                            Delete
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={9}
                    count={tabelTotalItems}
                    rowsPerPage={itemsPerPage}
                    page={tabelPage}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
}));
