import { Button } from "@material-ui/core";
import useAuth from "../../Components/Context/AuthContext/useAuth";
import useDashboard from "../../Components/Context/DashboardContext/useDashboard";

export function Logout() {
  const { setEmailCx, setAdminIdCx, setUserRoleCx, setLoggedCx } = useAuth()!;
  const { setFilterSediuCx } = useDashboard()!;

  function triggerLogOut() {
    setEmailCx("");
    setAdminIdCx(null);
    setUserRoleCx(null);
    setFilterSediuCx("");
    setLoggedCx(false);
    return;
  }
  return (
    <Button color="inherit" onClick={triggerLogOut}>
      Logout
    </Button>
  );
}

export default Logout;
