import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import ItemsSelectWithActions from "./ItemsSelectWithActions";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  FormControlLabel,
  FormControl,
  Button,
  TextField,
  RadioGroup,
  Radio,
  Container,
  Paper,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReactLoading from "react-loading";
import axios from "axios";
import useAuth from "../../../Components/Context/AuthContext/useAuth";

const bottomDistanceBetweenContainers = 7;

export function ClientServiceData() {
  interface ParamTypes {
    id: string | undefined;
  }

  const { id: pageId } = useParams<ParamTypes>();
  let history = useHistory();
  const classes = useStyles();

  const [sheetNumber, setSheetNumber] = useState("");
  const [values, setValues] = useState({
    tipGarantie: "1",
    recomandari: "",
  });

  const [partsOptions, setPartsOptions] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);

  const [defectsOptions, setDefectOptions] = useState([]);
  const [selectedDefects, setSelectedDefects] = useState<any>([]);

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [loading, setLoading] = useState(true);
  const [sheetStatus, setSheetStatus] = useState<number | null>(null);

  const { userRoleCx, adminIdCx } = useAuth()!;

  const disableInputs =
    (sheetStatus === 4 && !userRoleCx?.includes("4")) ||
    !(userRoleCx?.includes("2") || userRoleCx?.includes("4"));

  const handleChange =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  function handleDateChange(date: Date | null) {
    setSelectedDate(date ?? new Date());
  }

  // id piesa/ GARANTIE/ CANTIT
  async function updateFisa() {
    const status = selectedParts.length === 0 ? "2" : "3";

    interface DefectsTypes {
      id: number;
      garantie: boolean;
    }

    // id defect piesa
    const formatDefects = selectedDefects.map(
      (selectedDefect: DefectsTypes) => {
        return {
          id_defect: selectedDefect.id,
          garantie_defect: selectedDefect.garantie ?? false,
        };
      }
    );

    // date piesa
    interface ProductsTypes {
      id: number;
      cant: number;
      garantie: boolean;
    }

    const formatParts = selectedParts.map((parts: ProductsTypes) => {
      return {
        id_piesa: parts.id,
        cantitate_piesa: parts.cant,
        garantie_piesa: parts.garantie ?? false,
      };
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      id_fisa: pageId,
      defect: formatDefects,
      piese: formatParts,
      tip_garantie: values.tipGarantie,
      recomandari: values.recomandari,
      status: status,
      id_admin: adminIdCx,
      data_selectata: selectedDate,
    };

    try {
      await axios.post(`/update_service.php`, reservationData, config);
      history.push("/dashboard");
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function dropdownData() {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };

      try {
        const { data } = await axios.get(`/defecte.php`, config);
        setDefectOptions(data);
        getParts();
      } catch (error) {
        console.log(error);
      }
    }
    async function getParts() {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };

      try {
        const { data } = await axios.get(`/piese.php`, config);
        setPartsOptions(data);
        getValues();
      } catch (error) {
        console.log(error);
      }
    }

    async function getValues() {
      setLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const reservationData = {
        id_fisa: pageId,
      };

      try {
        const { data } = await axios.post(`/pas2.php`, reservationData, config);

        if (data[0].piese) {
          setSelectedParts(data[0].piese);
        }

        if (data[0].defecte) {
          setSelectedDefects(data[0].defecte);
        }
        setValues((values) => ({
          ...values,
          tipGarantie: data[0].garantie,
          recomandari: data[0].observatii,
        }));
        setSelectedDate(data[0].data_selectata ?? new Date());
        setSheetStatus(parseInt(data[0].status));
        setSheetNumber(data[0].numar);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    dropdownData();
  }, [pageId]);

  const handleChangeDefects = (value: any) => {
    setSelectedDefects(value);
  };

  const handleChangeParts = (value: any) => {
    setSelectedParts(value);
  };

  return (
    <>
      {sheetNumber && (
        <Box display="flex" justifyContent="space-between" margin={3}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Button disabled style={{ padding: 0, color: "black" }}>
              <Typography style={{ fontWeight: 700 }}>Numar fisa</Typography>
            </Button>
            <Button disabled style={{ padding: 0, color: "gray" }}>
              <Typography style={{ fontWeight: 700, fontSize: 15 }}>
                {sheetNumber}
              </Typography>
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                label="Data fisei"
                value={selectedDate}
                format="dd/MM/yyyy"
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                disabled={disableInputs}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
      )}
      <Paper className={classes.paper}>
        {loading ? (
          <ReactLoading type={"bubbles"} color={"#3f51b5"} />
        ) : (
          <FormControl fullWidth>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12}>
                <Box marginBottom={2}>
                  <Typography variant="h3" component="h1">
                    5.Cauze defect/Solutii
                  </Typography>
                </Box>
              </Grid>

              <Grid item md={12} sm={12}>
                <ItemsSelectWithActions
                  title="Monopere operatii"
                  setSelectedOptions={handleChangeDefects}
                  selectedOptions={selectedDefects}
                  options={defectsOptions}
                  disableInputs={disableInputs}
                />
              </Grid>
            </Grid>

            <Box
              marginTop={bottomDistanceBetweenContainers / 2}
              marginBottom={bottomDistanceBetweenContainers}
            >
              <hr />
            </Box>

            <Grid container spacing={2}>
              <Grid item md={12} sm={12}>
                <Box marginBottom={2}>
                  <Typography variant="h3" component="h1">
                    6.Piese inlocuite
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} sm={12}>
                <ItemsSelectWithActions
                  title="Piese disponibile"
                  setSelectedOptions={handleChangeParts}
                  selectedOptions={selectedParts}
                  options={partsOptions}
                  disableInputs={disableInputs}
                />
              </Grid>
            </Grid>

            <Box
              marginTop={bottomDistanceBetweenContainers / 2}
              marginBottom={bottomDistanceBetweenContainers}
            >
              <hr />
            </Box>

            <Grid container spacing={2}>
              <Grid item md={12} sm={12}>
                <Box marginBottom={2}>
                  <Typography variant="h3" component="h1">
                    7.Service
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} sm={12}>
                <FormControl disabled={disableInputs} component="fieldset">
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={values.tipGarantie}
                    onChange={handleChange("tipGarantie")}
                    row
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label="Garantie"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio color="secondary" />}
                      label="Garantie extinsa"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio color="default" />}
                      label="post Garantie"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Box
              marginTop={bottomDistanceBetweenContainers / 2}
              marginBottom={bottomDistanceBetweenContainers}
            >
              <hr />
            </Box>

            <Grid container spacing={2}>
              <Grid item md={12} sm={12}>
                <Box marginBottom={2}>
                  <Typography variant="h3" component="h1">
                    8. Recomandari pentru utilizare viitoare si alte note
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} sm={12}>
                <TextField
                  label=""
                  multiline
                  rowsMax="4"
                  fullWidth={true}
                  value={values.recomandari}
                  onChange={handleChange("recomandari")}
                  margin="normal"
                  variant="outlined"
                  disabled={disableInputs}
                />
              </Grid>
            </Grid>

            <Box
              marginTop={bottomDistanceBetweenContainers / 2}
              marginBottom={bottomDistanceBetweenContainers}
            >
              <hr />
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Button
                onClick={() => history.push("/dashboard")}
                variant="contained"
                color="primary"
              >
                <ArrowBackIcon />
                Iesire
              </Button>

              <Button
                style={{ right: 0 }}
                variant="contained"
                color="primary"
                onClick={updateFisa}
                disabled={disableInputs}
              >
                Salvare
                <ArrowForwardIcon />
              </Button>
            </Box>
          </FormControl>
        )}
      </Paper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },
}));

export default ClientServiceData;
