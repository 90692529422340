import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import UpdateIcon from "@material-ui/icons/Update";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 150,
    maxWidth: 160,
  },
}));

interface TableActionButtonsProps {
  id: number;
  userRole: string[] | null;
  sheetNumber: string;
  sheetDelete: (idFisa: number, item: string) => void;
}

const TableActionButtons = ({
  id,
  userRole,
  sheetNumber,
  sheetDelete,
}: TableActionButtonsProps) => {
  const classes = useStyles();

  const generatePdf = () => {
    window.open(
      `https://service.e-twow.app/genereaza_fisa.php?id=${id}`,
      "_blank"
    );
  };

  return (
    <Box display="flex" flexWrap="wrap">
      <Link to={`/customer/${id}`} className={classes.buttons}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          style={{ backgroundColor: "green" }}
          startIcon={<VisibilityIcon />}
        >
          Client
        </Button>
      </Link>
      {/* view button */}
      <Link to={`/customer-service/${id}`} className={classes.buttons}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<UpdateIcon />}
        >
          Service
        </Button>
      </Link>

      {(userRole?.includes("1") ||
        userRole?.includes("3") ||
        userRole?.includes("4")) && (
        <Link to={`/sheet-awb/${id}`} className={classes.buttons}>
          <Button
            variant="contained"
            color="default"
            fullWidth
            startIcon={<LocalShippingIcon />}
          >
            Finalizare
          </Button>
        </Link>
      )}
      {userRole?.includes("4") && (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => sheetDelete(id, sheetNumber)}
          startIcon={<DeleteIcon />}
          className={classes.buttons}
        >
          Sterge
        </Button>
      )}
      <Button
        variant="contained"
        fullWidth
        color="inherit"
        onClick={generatePdf}
        className={classes.buttons}
      >
        Generare pdf
      </Button>
    </Box>
  );
};

export default TableActionButtons;
