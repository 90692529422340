import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  TextField,
  Button,
  FormControl,
  Paper,
  Box,
  ButtonGroup,
  Theme,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactLoading from "react-loading";
import axios from "axios";
import useAuth from "../../../Components/Context/AuthContext/useAuth";
import { couriers } from "../../../Components/Config/DashboardDataConfig";
import { makeStyles } from "@material-ui/core/styles";

const bottomDistanceBetweenContainers = 7;

export default function Awb() {
  const classes = useStyles();

  const { adminIdCx, userRoleCx } = useAuth()!;
  const { id: pageId } = useParams<ParamTypes>();
  const history = useHistory();

  const [sheetNumber, setSheetNumber] = useState("");

  const [awbCode, setAwbCode] = useState("");
  const [curier, setCurier] = useState<string | null>("");

  const [loading, setLoading] = useState(true);

  const [sheetStatus, setSheetStatus] = useState<number | null>(null);

  const disableInputs = sheetStatus === 4 && !userRoleCx?.includes("4");

  interface ParamTypes {
    id: string | undefined;
  }

  function goBack() {
    return history.push("/");
  }

  async function postAwb(filterId: number) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      id_fisa: pageId,
      curier: curier,
      awb: awbCode,
      id_admin: adminIdCx,
      filter: filterId,
    };

    try {
      const res = await axios.post(`/update_awb.php`, reservationData, config);
      if (res.status === 200) goBack();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function getAwb() {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };

      try {
        const { data } = await axios.get(
          `/returnAwb.php?id_fisa=${pageId}`,
          config
        );
        setAwbCode(data.awb);
        setCurier(data.curier);
        setSheetStatus(parseInt(data.status));
        setSheetNumber(data.numar);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    if (pageId) getAwb();
  }, [pageId]);

  return (
    <>
      {sheetNumber && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          marginLeft={3}
        >
          <Button disabled style={{ padding: 0, color: "black" }}>
            <Typography style={{ fontWeight: 700 }}>Numar fisa</Typography>
          </Button>
          <Button disabled style={{ padding: 0, color: "gray" }}>
            <Typography style={{ fontWeight: 700, fontSize: 15 }}>
              {sheetNumber}
            </Typography>
          </Button>
        </Box>
      )}
      <Paper className={classes.paper}>
        {loading ? (
          <ReactLoading type={"bubbles"} color={"#3f51b5"} />
        ) : (
          <FormControl fullWidth>
            <Autocomplete
              id="curierat"
              fullWidth={true}
              options={couriers}
              onChange={(event, value: string | null) => setCurier(value)}
              value={curier}
              disabled={disableInputs}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Firma Curierat"
                  margin="dense"
                  variant="standard"
                  fullWidth
                />
              )}
            />
            <TextField
              autoFocus={false}
              margin="dense"
              id="name"
              label="AWB CODE"
              type="text"
              fullWidth={true}
              value={awbCode}
              onChange={(e) => setAwbCode(e.target.value)}
              disabled={disableInputs}
            />
            <Box
              marginTop={bottomDistanceBetweenContainers / 2}
              marginBottom={bottomDistanceBetweenContainers}
            />

            <Box display="flex" justifyContent="space-between">
              <Button color="primary" variant="contained" onClick={goBack}>
                Anuleaza
              </Button>
              <ButtonGroup
                color="primary"
                variant="contained"
                disabled={disableInputs}
              >
                <Button onClick={() => postAwb(0)}>Ridicare personala</Button>
                <Button onClick={() => postAwb(1)}>
                  Salvare date expediere
                </Button>
              </ButtonGroup>
            </Box>
          </FormControl>
        )}
      </Paper>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },
}));
