import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Box, Paper } from "@material-ui/core";

export default function AddDefect() {
  const classes = useStyles();
  const [addDefect, setAddDefect] = useState("");
  const [timpDefect, setTimpDefect] = useState("");
  const [displayError, setDisplayError] = useState(false);

  function postDefect() {
    if (addDefect !== "" && timpDefect !== "") {
      fetchPostDefect();
    } else {
      setDisplayError(true);
    }
  }

  async function fetchPostDefect() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      defect: addDefect,
      timp: timpDefect,
    };

    try {
      const res = await axios.post(`/add_defect.php`, reservationData, config);
      if (res.status === 200) {
        setAddDefect("");
        setTimpDefect("");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Paper>
      <Box padding={4}>
        <TextField
          label="Adaugati defect"
          fullWidth={true}
          value={addDefect}
          onChange={(e) => setAddDefect(e.target.value)}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          required
          error={displayError && addDefect === ""}
          helperText={
            displayError && addDefect === "" ? "Campul este gol!" : " "
          }
        />
        <TextField
          label="Timp reparatie"
          fullWidth={true}
          value={timpDefect}
          onChange={(e: any) =>
            setTimpDefect(e.target.value >= 1 ? e.target.value : "")
          }
          inputProps={{ min: "1", step: "1" }}
          type="number"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          required
          error={displayError && timpDefect === ""}
          helperText={
            displayError && timpDefect === "" ? "Campul este gol!" : " "
          }
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={postDefect}
        >
          Salvati
        </Button>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  textField: {
    // flexBasis: 200,
    marginBottom: 1,
  },
}));
