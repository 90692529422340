import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/styles";
import { Box, ButtonGroup, Container, Paper } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { locations } from "../../Components/Config/DashboardDataConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
}));

export default function RaportPage() {
  const classes = useStyles();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const [selectedLocation, setSelectedLocation] = useState("0");

  function handleStartDateChange(date: MaterialUiPickersDate) {
    setSelectedStartDate(date ?? new Date());
  }

  function handleEndDateChange(date: MaterialUiPickersDate) {
    setSelectedEndDate(date ?? new Date());
  }

  function handleLocationChange(location: any) {
    setSelectedLocation(location.target.value);
  }

  function createPdf(url: string) {
    const formattedStartingDate = selectedStartDate.toISOString().slice(0, 10);
    const formattedEndDate = selectedEndDate.toISOString().slice(0, 10);

    const sendingData = `?start=${formattedStartingDate}&stop=${formattedEndDate}&selectedLocation=${selectedLocation}`;
    const completeUrl = `${url}${sendingData}`;
    window.open(completeUrl, "_blank");
  }

  return (
    <Container>
      <Paper elevation={1} className={classes.root}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            label="Data start"
            value={selectedStartDate}
            format="dd/MM/yyyy"
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            fullWidth={true}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            label="Data final"
            value={selectedEndDate}
            format="dd/MM/yyyy"
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            fullWidth={true}
          />
        </MuiPickersUtilsProvider>
        <FormControl fullWidth={true}>
          <InputLabel htmlFor="age-simple">Sediu</InputLabel>
          <Select
            value={selectedLocation}
            onChange={handleLocationChange}
            inputProps={{
              name: "age",
              id: "age-simple",
            }}
          >
            {locations?.map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box marginTop={2} display="flex">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            endIcon={<ArrowForwardIcon />}
            onClick={() =>
              createPdf(`https://service.e-twow.app/genereaza_pdf.php`)
            }
            style={{ margin: 2 }}
          >
            Raport general
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            endIcon={<ArrowForwardIcon />}
            onClick={() =>
              createPdf(`https://service.e-twow.app/genereaza_manopera.php`)
            }
            style={{ margin: 2 }}
          >
            Raport manopera
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            endIcon={<ArrowForwardIcon />}
            onClick={() =>
              createPdf(`https://service.e-twow.app/genereaza_fiseInLucru.php`)
            }
            style={{ margin: 2 }}
          >
            Raport fise in lucru
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
