import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  FormControl,
  Button,
  Grid,
  TextField,
  Box,
  Paper,
  Typography,
} from "@material-ui/core";
import ReactLoading from "react-loading";
import axios from "axios";
import useAuth from "../../../Components/Context/AuthContext/useAuth";
import useDashboard from "../../../Components/Context/DashboardContext/useDashboard";

export function CustomerData() {
  interface ParamTypes {
    id: string | undefined;
  }

  const bottomDistanceBetweenContainers = 7;

  const { id: pageId } = useParams<ParamTypes>();
  let history = useHistory();
  const classes = useStyles();

  const [values, setValues] = useState({
    id: "",
    numeClient: "",
    adress: "",
    phoneNumber: "",
    invoiceNumber: "",
    email: "",
    serie: "",
    totalKm: "",
    defect: "",
    condition: "",
    orderNumber: "",
  });

  const [sheetNumber, setSheetNumber] = useState("");
  const [scooterModel, setScooterModel] = useState<{
    id_nom: string;
    model: string;
  }>({
    id_nom: "",
    model: "",
  });
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [models, setModels] = useState<{ model: string; id_nom: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const [submitedPressedError, setSubmitedPressedError] = useState(false);
  const [sheetStatus, setSheetStatus] = useState<number | null>(null);
  const [saveLoading, setSaveLoading] = useState(false);

  const { userRoleCx, adminIdCx } = useAuth()!;
  const { filterSediuCx } = useDashboard()!;

  const disableInputs = sheetStatus === 4 && !userRoleCx?.includes("4");

  function handleDateChange(date: Date | null) {
    setSelectedDate(date ?? new Date());
  }

  async function postFisa() {
    if (
      values.numeClient !== "" &&
      values.adress !== "" &&
      values.phoneNumber !== "" &&
      values.defect !== "" &&
      values.serie !== "" &&
      values.condition !== "" &&
      scooterModel.id_nom !== ""
    ) {
      setSubmitedPressedError(false);
      setSaveLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const reservationData = {
        id_fisa: pageId,
        nume: values.numeClient,
        adresa: values.adress,
        telefon: values.phoneNumber,
        factura: values.invoiceNumber,
        serie: values.serie,
        km: values.totalKm,
        id_nom: scooterModel.id_nom,
        defect_reclamat: values.defect,
        stare_produs: values.condition,
        email: values.email,
        nr_ordine: values.orderNumber,
        data_selectata: selectedDate,
        sediu: filterSediuCx,
        id_admin: adminIdCx,
      };

      try {
        await axios.post(`/creaza_fisa.php`, reservationData, config);
        setSaveLoading(false);
        history.push("/dashboard");
      } catch (error) {
        setSaveLoading(false);
        console.log(error);
      }
    } else {
      setSubmitedPressedError(true);
    }
  }

  useEffect(() => {
    const getValues = async (models: any) => {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const reservationData = {
        id_fisa: pageId,
      };

      try {
        const { data } = await axios.post(`/pas1.php`, reservationData, config);

        // aici

        data?.forEach((clientData: any) => {
          const {
            data_selectata,
            id_fisa,
            nume,
            adresa,
            telefon,
            nr_factura,
            email,
            serie,
            km,
            id_nom,
            defect_reclamat,
            stare_produs,
            nr_ordine,
            status,
            numar,
          } = clientData!;
          const modelColorName = models?.filter(
            (color: any) => id_nom === color?.id_nom
          )[0];

          setSelectedDate(data_selectata ?? new Date());
          setScooterModel(modelColorName);
          console.log(data);

          setValues((values) => ({
            ...values,
            id: id_fisa,
            numeClient: nume,
            adress: adresa,
            phoneNumber: telefon,
            invoiceNumber: nr_factura,
            email: email,
            serie: serie,
            totalKm: km,
            defect: defect_reclamat,
            condition: stare_produs,
            orderNumber: nr_ordine,
          }));
          setSheetStatus(parseInt(status));
          setSheetNumber(numar);
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    const dropdownModelsData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      try {
        const { data } = await axios.get(`/nomenclator.php`, config);
        setModels(data);
        getValues(data);
      } catch (error) {
        console.log(error);
      }
    };

    dropdownModelsData();
  }, [pageId]);

  const handleChange =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({
        ...values,
        [prop]:
          prop === "serie"
            ? event.target.value
                .toUpperCase()
                .replace(/[&\\#,+()$~%.'":*?<>{}!^-_=+|`~@ ]/g, "")
            : event.target.value,
      });
    };

  const rowsInputs = [
    {
      label: "Nume client",
      type: "text",
      value: values.numeClient,
      handleChange: handleChange("numeClient"),
      inputProps: {},
      error: submitedPressedError && values.numeClient === "",
      helperText:
        submitedPressedError && values.numeClient === ""
          ? "Campul este gol!"
          : " ",
      disabled: disableInputs,
    },
    {
      label: "Adresa",
      type: "text",
      value: values.adress,
      handleChange: handleChange("adress"),
      inputProps: {},
      error: submitedPressedError && values.adress === "",
      helperText:
        submitedPressedError && values.adress === "" ? "Campul este gol!" : " ",
      disabled: disableInputs,
    },
    {
      label: "Nr. tel",
      type: "tel",
      value: values.phoneNumber,
      handleChange: handleChange("phoneNumber"),
      inputProps: {
        maxLength: 15,
      },
      error: submitedPressedError && values.phoneNumber === "",
      helperText:
        submitedPressedError && values.phoneNumber === ""
          ? "Campul este gol!"
          : " ",
      disabled: disableInputs,
    },
    {
      label: "Numar factura",
      type: "text",
      value: values.invoiceNumber,
      handleChange: handleChange("invoiceNumber"),
      inputProps: {},
      error: false,
      helperText: null,
      disabled: disableInputs,
    },
    {
      label: "Nr. de ordine",
      type: "text",
      value: values.orderNumber,
      handleChange: handleChange("orderNumber"),
      inputProps: {},
      error: false,
      helperText: null,
      disabled: disableInputs,
    },
    {
      label: "Email",
      type: "text",
      value: values.email,
      handleChange: handleChange("email"),
      inputProps: {
        maxLength: 30,
      },
      error: false,
      helperText: null,
      disabled: disableInputs,
    },
  ];

  const handleModelChange = (
    value: { id_nom: string; model: string } | null
  ) => {
    if (value) {
      console.log(value);
      return setScooterModel({
        model: value.model,
        id_nom: value.id_nom,
      });
    } else
      return setScooterModel({
        model: "",
        id_nom: "",
      });
  };

  return (
    <>
      {sheetNumber && (
        <Box display="flex" justifyContent="space-between" margin={3}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Button disabled style={{ padding: 0, color: "black" }}>
              <Typography style={{ fontWeight: 700 }}>Numar fisa</Typography>
            </Button>
            <Button disabled style={{ padding: 0, color: "gray" }}>
              <Typography style={{ fontWeight: 700, fontSize: 15 }}>
                {sheetNumber}
              </Typography>
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                label="Data fisei"
                value={selectedDate}
                format="dd/MM/yyyy"
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                disabled={disableInputs}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
      )}
      <Paper className={classes.paper}>
        {loading ? (
          <ReactLoading type={"bubbles"} color={"#3f51b5"} />
        ) : (
          <FormControl fullWidth>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12}>
                <Box marginBottom={2}>
                  <Typography variant="h3" component="h1">
                    1.Date client
                  </Typography>
                </Box>
              </Grid>

              {rowsInputs.map((firstRowInput, idx) => {
                const {
                  label,
                  value,
                  error,
                  handleChange,
                  helperText,
                  inputProps,
                  type,
                  disabled,
                } = firstRowInput;
                return (
                  <Grid item md={4} sm={12} key={idx}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type={type}
                      label={label}
                      value={value}
                      onChange={handleChange}
                      error={error}
                      helperText={helperText}
                      inputProps={inputProps}
                      disabled={disabled}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Box
              marginTop={bottomDistanceBetweenContainers / 2}
              marginBottom={bottomDistanceBetweenContainers}
            >
              <hr />
            </Box>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12}>
                <Box marginBottom={2}>
                  <Typography variant="h3" component="h1">
                    2.Date produs
                  </Typography>
                </Box>
              </Grid>

              <Grid item md={4} sm={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Serie"
                  value={values.serie}
                  onChange={handleChange("serie")}
                  error={submitedPressedError && values.serie === ""}
                  helperText={
                    submitedPressedError && values.serie === ""
                      ? "Campul este gol!"
                      : " "
                  }
                  disabled={disableInputs}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Km total"
                  value={values.totalKm}
                  onChange={handleChange("totalKm")}
                  disabled={disableInputs}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <Autocomplete
                  fullWidth
                  options={models}
                  getOptionLabel={(option) => option.model.toString()}
                  getOptionSelected={(option) =>
                    option.model === scooterModel.model
                  }
                  value={scooterModel}
                  disabled={disableInputs}
                  onChange={(_, value) => {
                    if (value) handleModelChange(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Model/Culoare"
                      variant="outlined"
                      fullWidth
                      helperText={
                        submitedPressedError && !scooterModel.id_nom
                          ? "Campul este gol!"
                          : " "
                      }
                      error={submitedPressedError && scooterModel.id_nom === ""}
                    />
                  )}
                />
              </Grid>
              {/* da */}
            </Grid>
            <Box
              marginTop={bottomDistanceBetweenContainers / 2}
              marginBottom={bottomDistanceBetweenContainers}
            >
              <hr />
            </Box>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12}>
                <Box marginBottom={2}>
                  <Typography variant="h3" component="h1">
                    3.Defect reclamat{" "}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} sm={12}>
                <TextField
                  label=""
                  multiline
                  rowsMax="4"
                  value={values.defect}
                  fullWidth
                  onChange={handleChange("defect")}
                  margin="normal"
                  variant="outlined"
                  error={submitedPressedError && values.defect === ""}
                  helperText={
                    submitedPressedError && values.defect === ""
                      ? "Campul este gol!"
                      : " "
                  }
                  disabled={disableInputs}
                />
              </Grid>
            </Grid>
            <Box
              marginTop={bottomDistanceBetweenContainers / 2}
              marginBottom={bottomDistanceBetweenContainers}
            >
              <hr />
            </Box>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12}>
                <Box marginBottom={2}>
                  <Typography variant="h3" component="h1">
                    4.Stare produs si accesorii primite/trimise
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} sm={12}>
                <TextField
                  fullWidth
                  label=""
                  multiline
                  rowsMax="4"
                  value={values.condition}
                  onChange={handleChange("condition")}
                  margin="normal"
                  variant="outlined"
                  error={submitedPressedError && values.condition === ""}
                  helperText={
                    submitedPressedError && values.condition === ""
                      ? "Campul este gol!"
                      : " "
                  }
                  disabled={disableInputs}
                />
              </Grid>
            </Grid>
            <Box
              marginTop={bottomDistanceBetweenContainers / 2}
              marginBottom={bottomDistanceBetweenContainers}
            >
              <hr />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Button
                onClick={() => history.push("/dashboard")}
                variant="contained"
                color="primary"
                startIcon={<ArrowBackIcon />}
                disabled={saveLoading}
              >
                Iesire
              </Button>
              <Button
                onClick={postFisa}
                variant="contained"
                color="primary"
                disabled={disableInputs || saveLoading}
                endIcon={<ArrowForwardIcon />}
              >
                Salvati
              </Button>
            </Box>
          </FormControl>
        )}
      </Paper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },
}));

export default CustomerData;
