import { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import UpdateIcon from "@material-ui/icons/Update";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddDefect from "./AddDefect";
import EditDefects from "../../Components/utils/EditPart";
import DeleteDefects from "../../Components/utils/DeleteConfirm";
import axios from "axios";
import {
  ButtonGroup,
  Container,
  InputAdornment,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { Paper } from "@material-ui/core";

export default function DefectsPage() {
  const [defecte, setDefecte] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [modalValues, setModalValues] = useState<{
    id: null | number;
    nume_ro: string;
    timp: string;
  }>({
    id: null,
    nume_ro: "",
    timp: "",
  });
  const [action, setAction] = useState("");
  const [deleteItemId, setDeleteItemId] = useState({
    id_defect: "",
    nume_ro: "",
  });
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  function handleClickOpen(id_defect: number, defect: string, timp: string) {
    setModalValues({
      id: id_defect,
      nume_ro: defect,
      timp: timp,
    });
    setOpenModalEdit(true);
  }

  function handleClose() {
    setOpenModalEdit(false);
  }

  function handleClickOpenDeleteAlert(id_defect: string, defect: string) {
    setDeleteItemId({ id_defect, nume_ro: defect });
    setOpenDeleteAlert(true);
  }

  function handleClickCloseDeleteAlert() {
    setOpenDeleteAlert(false);
  }

  let findDefect = defecte.filter((defect: any) => {
    return (
      defect.nume_ro.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      defect.timp.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  async function getDefecte() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    setLoading(true);
    try {
      const res = await axios.get(`/defecte.php`, config);
      setDefecte(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const updateDefect = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      id_defect: modalValues.id,
      defect: modalValues.nume_ro,
      timp: modalValues.timp,
    };
    try {
      const res = await axios.post(
        `/update_defect.php`,
        reservationData,
        config
      );

      if (res.status === 200) {
        setAction("");
        setLoading(false);
        setModalValues({
          id: null,
          nume_ro: "",
          timp: "",
        });
        getDefecte();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDefect = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      id_defect: deleteItemId.id_defect,
    };
    try {
      const res = await axios.post(
        `/delete_defect.php`,
        reservationData,
        config
      );
      if (res.status === 200) {
        setAction("");
        setLoading(false);
        setDeleteItemId({
          id_defect: "",
          nume_ro: "",
        });
        getDefecte();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDefecte();
  }, []);

  useEffect(() => {
    if (action === "update") updateDefect();
    else if (action === "delete") deleteDefect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    <>
      <EditDefects
        handleClose={handleClose}
        openModal={openModalEdit}
        modalValues={modalValues}
        setModalValues={setModalValues}
        title={modalValues.nume_ro}
        setAction={setAction}
      />
      <DeleteDefects
        handleClose={handleClickCloseDeleteAlert}
        openModalDelete={openDeleteAlert}
        setDeleteConfirm={setAction}
        title={deleteItemId.nume_ro}
      />

      <Box margin={3}>
        <Box marginBottom={3} marginTop={3}>
          <AddDefect />
        </Box>
        <Paper>
          <Box
            display="flex"
            alignItems="center"
            padding={5}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems={"center"}>
              <Box marginTop={3} marginRight={3}></Box>
              <TextField
                id="input-with-icon-grid"
                label="Cautati defecte"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                value={search}
              />
            </Box>
            {loading && <CircularProgress />}
          </Box>
          <TableContainer>
            <table
              style={{
                tableLayout: "fixed",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th className="table-items">
                    <Typography component="div">
                      <Box fontWeight="fontWeightBold">Nume</Box>
                    </Typography>
                  </th>
                  <th className="table-items">
                    <Typography component="div">
                      <Box fontWeight="fontWeightBold"> Timp reparatie</Box>
                    </Typography>
                  </th>
                  <th className="table-items">
                    <Typography component="div">
                      <Box fontWeight="fontWeightBold">
                        Butoane de configurare
                      </Box>
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {findDefect.length < 1 ? (
                  <tr>
                    <td style={{ display: "hidden" }}></td>
                    <td>
                      <p style={{ textAlign: "center", margin: "30px" }}>
                        Este gol
                      </p>
                    </td>
                  </tr>
                ) : (
                  findDefect.map((elem: any, rowIndex: number) => (
                    <tr key={rowIndex}>
                      <th className="table-items">{elem.nume_ro}</th>
                      <th className="table-items">{elem.timp}</th>
                      <th className="table-items">
                        <ButtonGroup>
                          {/* view button */}
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<UpdateIcon />}
                            onClick={() =>
                              handleClickOpen(
                                elem.id_defect,
                                elem.nume_ro,
                                elem.timp
                              )
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon />}
                            onClick={() =>
                              handleClickOpenDeleteAlert(
                                elem.id_defect,
                                elem.nume_ro
                              )
                            }
                          >
                            Delete
                          </Button>
                        </ButtonGroup>
                      </th>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}
